import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Vimeo from './Vimeo';
import '../../assets/styles/components/vimeo-video.scss';

const LandscapeMedia = forwardRef((props, ref) => {
  const { data } = props;
  const {
    video_embed_gallerysections_vimeo_video_id,
    video_embed_gallerysections_vimeo_title,
    video_embed_gallerysections_vimeo_caption,
    aspect_ratio,
  } = data;

  return (
    <div className="page-section container" ref={ref}>
      <div className="vimeo-video--wrapper">
        <Vimeo vimeoId={video_embed_gallerysections_vimeo_video_id} aspectRatio={aspect_ratio} />
        <div className="gallery-section-copy--wrapper">
          {video_embed_gallerysections_vimeo_title && (
            <h2>{video_embed_gallerysections_vimeo_title}</h2>
          )}
          {video_embed_gallerysections_vimeo_caption && (
            <p>{video_embed_gallerysections_vimeo_caption}</p>
          )}
        </div>
      </div>
    </div>
  );
});

LandscapeMedia.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default LandscapeMedia;
