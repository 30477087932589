import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Media from '../Media';
import { createBlockClass, fetchImage } from '../../utils/helper';
import '../../assets/styles/components/three-column-media.scss';

const ThreeColumnMedia = forwardRef((props, ref) => {
  const { data } = props;
  const {
    three_gallerysections_image_firstGatsby,
    three_gallerysections_image_first,
    three_gallerysections_image_first_alt,
    three_gallerysections_image_second,
    three_gallerysections_image_secondGatsby,
    three_gallerysections_image_second_alt,
    three_gallerysections_image_third,
    three_gallerysections_image_thirdGatsby,
    three_gallerysections_image_third_alt,
    three_gallerysections_video_first,
    three_gallerysections_video_second,
    three_gallerysections_video_third,
    three_gallerysections_vimeo_first,
    three_gallerysections_vimeo_second,
    three_gallerysections_vimeo_third,
    three_gallerysections_video_first_title,
    three_gallerysections_video_first_caption,
    three_gallerysections_video_second_title,
    three_gallerysections_video_second_caption,
    three_gallerysections_video_third_title,
    three_gallerysections_video_third_caption,
    block_type,
    aspect_ratio,
  } = data;

  const gettingImage = useCallback((gatsbyImage, image) => fetchImage(gatsbyImage, image), []);

  return (
    <div
      className={`gallery-section three-column-media--wrapper container ${createBlockClass(block_type)}`}
      ref={ref}
    >
      <div className="three-column-media--column-one">
        <div className={`three-column-media ${aspect_ratio}`}>
          <Media
            img={gettingImage(three_gallerysections_image_firstGatsby, three_gallerysections_image_first)}
            alt={three_gallerysections_image_first_alt}
            htmlVideo={three_gallerysections_video_first}
            vimeoId={three_gallerysections_vimeo_first}
            aspectRatio={aspect_ratio}
          />
        </div>
        <div className="gallery-section-copy--wrapper">
          {three_gallerysections_video_first_title && (
            <h2>{three_gallerysections_video_first_title}</h2>
          )}
          {three_gallerysections_video_first_caption && (
            <p>{three_gallerysections_video_first_caption}</p>
          )}
        </div>
      </div>
      <div className="three-column-media--column-two">
        <div className={`three-column-media ${aspect_ratio}`}>
          <Media
            img={gettingImage(three_gallerysections_image_secondGatsby, three_gallerysections_image_second)}
            alt={three_gallerysections_image_second_alt}
            htmlVideo={three_gallerysections_video_second}
            vimeoId={three_gallerysections_vimeo_second}
            aspectRatio={aspect_ratio}
          />
        </div>
        <div className="gallery-section-copy--wrapper">
          {three_gallerysections_video_second_title && (
            <h2>{three_gallerysections_video_second_title}</h2>
          )}
          {three_gallerysections_video_second_caption && (
            <p>{three_gallerysections_video_second_caption}</p>
          )}
        </div>
      </div>
      <div className="three-column-media--column-three">
        <div className={`three-column-media ${aspect_ratio}`}>
          <Media
            img={gettingImage(three_gallerysections_image_thirdGatsby, three_gallerysections_image_third)}
            alt={three_gallerysections_image_third_alt}
            htmlVideo={three_gallerysections_video_third}
            vimeoId={three_gallerysections_vimeo_third}
            aspectRatio={aspect_ratio}
          />
        </div>
        <div className="gallery-section-copy--wrapper">
          {three_gallerysections_video_third_title && (
            <h2>{three_gallerysections_video_third_title}</h2>
          )}
          {three_gallerysections_video_third_caption && (
            <p>{three_gallerysections_video_third_caption}</p>
          )}
        </div>
      </div>
    </div>
  );
});

ThreeColumnMedia.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default ThreeColumnMedia;
