import React, {
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Parallax, Background } from 'react-parallax';
import Media from './Media';
import LandscapeMedia from './GallerySections/LandscapeMedia';
import TwoColumnMedia from './GallerySections/TwoColumnMedia';
import ThreeColumnMedia from './GallerySections/ThreeColumnMedia';
import Video from './GallerySections/Video';
import ProjectTile from './ProjectTile';
import NextSection from './PageSections/NextSection';
import CreateLink from './CreateLink';
import { renderSlug, observer } from '../utils/helper';
import '../assets/styles/templates/gallery.scss';

/* global window */
function Gallery({ gallery, allGallery }) {
  const tileEl = useRef(null);
  const lazyImageObserver = useRef(null);
  const [toggleProjectInfo, setToggleProjectInfo] = useState(false);
  const {
    sections,
    title,
    project_information,
    body,
    featured_media,
    featured_mediaGatsby,
    related_projects_gallery: {
      related_projects_gallery_item,
    },
  } = gallery;

  const getPrevAndNext = useCallback((type) => {
    const projectIdx = parseInt(Object.keys(allGallery).filter((g) => allGallery[g].node.content.title === title)[0], 10);
    let prevIdx = projectIdx;
    let nextIdx = projectIdx;
    prevIdx = projectIdx === 0 ? allGallery.length - 1 : prevIdx -= 1;
    nextIdx = projectIdx === (allGallery.length - 1) ? 0 : nextIdx += 1;
    const projectPag = type === 'prev' ? allGallery[prevIdx].node.content : allGallery[nextIdx].node.content;
    const renderedSlug = renderSlug(projectPag.publicationDate, projectPag.slug, projectPag.templateKey);
    return renderedSlug;
  }, [allGallery, title]);

  useEffect(() => {
    let isMounted = true;
    const img = tileEl.current;
    if (isMounted) {
      if (typeof window !== 'undefined') {
        if ('IntersectionObserver' in window) {
          lazyImageObserver.current = observer(lazyImageObserver.current, window);

          if (img && lazyImageObserver.current) {
            lazyImageObserver.current.observe(img);
          }
        }
      }
    }

    return () => {
      isMounted = false;
      if (img !== null) {
        if (img.target) {
          lazyImageObserver.current.unobserve(img.target);
        }
      }
    };
  }, []);

  const getComponent = useCallback(
    (type, obj) => {
      const exists = Object.values(obj).filter((o) => o !== null);
      if (exists.length === 0) {
        return null;
      }

      const components = {
        landscape_image_video_gallerysections: LandscapeMedia,
        two_gallerysections: TwoColumnMedia,
        three_gallerysections: ThreeColumnMedia,
        video_embed_gallerysections: Video,
      };

      if (typeof components[type] !== 'undefined') {
        return components[type];
      }

      return null;
    },
    [],
  );

  return (
    <>
      {featured_media && (
        <div className="gallery-single--featured-image-wrapper">
          <Parallax strength={500}>
            <Background className="gallery-single--featured-image-background">
              <div className="gallery-single--featured-image">
                <Media
                  img={featured_mediaGatsby || featured_media}
                  alt={featured_media.image_alt}
                  htmlVideo={featured_media.video}
                />
              </div>
            </Background>
          </Parallax>
          <NextSection />
        </div>
      )}
      <div className="container gallery-single--featured-text">
        <h1>{title}</h1>
        {toggleProjectInfo === false && body && (
          <div className="gallery-single--body-text">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {body}
            </ReactMarkdown>
          </div>
        )}
        {toggleProjectInfo === true && project_information && (
          <div className="gallery-single--project-information">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {project_information}
            </ReactMarkdown>
          </div>
        )}
        {project_information && (
          <button
            className="pill btn"
            type="button"
            onClick={() => setToggleProjectInfo(!toggleProjectInfo)}
          >
            <span>
              {toggleProjectInfo ? 'Close  –' : 'View Project information  +'}
            </span>
          </button>
        )}
        <div className="gallery-single--pagination">
          <CreateLink link={getPrevAndNext('prev')} classWrapper="gallery-single--pagination-link">
            Previous project
          </CreateLink>
          <CreateLink link={getPrevAndNext()} classWrapper="gallery-single--pagination-link">
            Next project
          </CreateLink>
        </div>
      </div>

      {sections
        && sections.map((section, idx) => {
          const SpecificSection = getComponent(section.type, section);
          if (SpecificSection !== null) {
            return <SpecificSection key={`${section.type} ${idx}`} data={section} />;
          }

          return null;
        })}

      <div className="container gallery-single--pagination">
        <CreateLink link={getPrevAndNext('prev')} classWrapper="gallery-single--pagination-link">
          Previous project
        </CreateLink>
        <CreateLink link={getPrevAndNext()} classWrapper="gallery-single--pagination-link">
          Next project
        </CreateLink>
      </div>

      {related_projects_gallery_item.length > 0 && typeof allGallery !== 'undefined' && (
        <div className="related-projects--wrapper container gallery-wrapper">
          <h2>Related Projects</h2>
          <div className="related-projects--item-wrapper">
            {related_projects_gallery_item.map((item) => {
              const project = allGallery.filter(({ node }) => node.content.title === item)[0];
              if (typeof project !== 'undefined') {
                return (
                  <ProjectTile
                    key={project.node.content.slug}
                    tile={project.node.content}
                    slug={project.node.content.slug}
                  />
                );
              }

              return null;
            })}
          </div>
        </div>
      )}
    </>
  );
}

Gallery.propTypes = {
  gallery: PropTypes.shape().isRequired,
  allGallery: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Gallery;
