import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Media from '../Media';
import { createBlockClass, fetchImage } from '../../utils/helper';
import '../../assets/styles/components/two-column-media.scss';

const TwoColumnMedia = forwardRef((props, ref) => {
  const { data } = props;
  const {
    two_gallerysections_image_left,
    two_gallerysections_image_leftGatsby,
    two_gallerysections_image_left_alt,
    two_gallerysections_image_right,
    two_gallerysections_image_rightGatsby,
    two_gallerysections_image_right_alt,
    two_gallerysections_video_right,
    two_gallerysections_vimeo_right,
    two_gallerysections_video_left,
    two_gallerysections_vimeo_left,
    two_gallerysections_video_right_title,
    two_gallerysections_video_right_caption,
    two_gallerysections_video_left_title,
    two_gallerysections_video_left_caption,
    block_type,
    aspect_ratio,
  } = data;

  const gettingImage = useCallback((gatsbyImage, image) => fetchImage(gatsbyImage, image), []);

  return (
    <div
      className={`gallery-section two-column-media--wrapper container ${createBlockClass(block_type)}`}
      ref={ref}
    >
      <div className="two-column-media--column-one">
        <div className={`two-column-media ${aspect_ratio}`}>
          <Media
            img={gettingImage(two_gallerysections_image_leftGatsby, two_gallerysections_image_left)}
            alt={two_gallerysections_image_left_alt}
            htmlVideo={two_gallerysections_video_left}
            vimeoId={two_gallerysections_vimeo_left}
            aspectRatio={aspect_ratio}
          />
        </div>
        <div className="gallery-section-copy--wrapper">
          {two_gallerysections_video_left_title && (
            <h2>{two_gallerysections_video_left_title}</h2>
          )}
          {two_gallerysections_video_left_caption && (
            <p>{two_gallerysections_video_left_caption}</p>
          )}
        </div>
      </div>
      <div className="two-column-media--column-two">
        <div className={`two-column-media ${aspect_ratio}`}>
          <Media
            img={gettingImage(two_gallerysections_image_rightGatsby, two_gallerysections_image_right)}
            alt={two_gallerysections_image_right_alt}
            htmlVideo={two_gallerysections_video_right}
            vimeoId={two_gallerysections_vimeo_right}
            aspectRatio={aspect_ratio}
          />
        </div>
        <div className="gallery-section-copy--wrapper">
          {two_gallerysections_video_right_title && (
            <h2>{two_gallerysections_video_right_title}</h2>
          )}
          {two_gallerysections_video_right_caption && (
            <p>{two_gallerysections_video_right_caption}</p>
          )}
        </div>
      </div>
    </div>
  );
});

TwoColumnMedia.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default TwoColumnMedia;
