import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import GalleryComponent from '../components/Gallery';
import '../assets/styles/templates/gallery.scss';

function Gallery({ data }) {
  if (data.galleries === null) {
    return null;
  }

  const { content } = data.galleries;
  const { edges } = data.allGalleries;
  const { seo_description, seo_title, header_dark } = content;

  return (
    <Layout
      classWrapper="header-over gallery-single--page"
      headerDark={header_dark}
    >
      <SEO title={seo_title} description={seo_description} />
      <GalleryComponent gallery={content} allGallery={edges} />
    </Layout>
  );
}

Gallery.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Gallery;

export const GalleryQuery = graphql`
  query GalleryPage($id: String!) {
    allGalleries(filter: { content: { published: { eq: true } } }) {
      edges {
        node {
          content {
            title
            body
            excerpt
            templateKey
            publicationDate
            slug
            thumbnailImageGatsby {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
            thumbnailImage {
              video
              image_alt
            }
          }
        }
      }
    }
    galleries(content: { id: { eq: $id } }) {
      content {
        body
        seo_description
        seo_title
        title
        header_dark
        featured_mediaGatsby {
          childImageSharp {
            gatsbyImageData
          }
          publicURL
        }
        featured_media {
          image_alt
          video
        }
        related_projects_gallery {
          related_projects_gallery_item
        }
        project_information
        sections {
          block_type
          aspect_ratio
          landscape_image_video_gallerysections_image
          landscape_image_video_gallerysections_imageGatsby {
            childImageSharp {
              gatsbyImageData
            }
            url
            publicURL
          }
          landscape_image_video_gallerysections_image_alt
          landscape_image_video_gallerysections_video
          landscape_image_video_gallerysections_videoGatsby {
            childImageSharp {
              gatsbyImageData
            }
            url
            publicURL
          }
          landscape_image_video_gallerysections_vimeo_video_id
          landscape_image_video_gallerysections_title
          landscape_image_video_gallerysections_caption
          three_gallerysections_image_first
          three_gallerysections_image_firstGatsby {
            childImageSharp {
              gatsbyImageData
            }
            url
            publicURL
          }
          three_gallerysections_image_first_alt
          three_gallerysections_image_second
          three_gallerysections_image_secondGatsby {
            childImageSharp {
              gatsbyImageData
            }
            url
            publicURL
          }
          three_gallerysections_image_second_alt
          three_gallerysections_image_third
          three_gallerysections_vimeo_first
          three_gallerysections_vimeo_second
          three_gallerysections_vimeo_third
          three_gallerysections_video_first_title
          three_gallerysections_video_first_caption
          three_gallerysections_video_second_title
          three_gallerysections_video_second_caption
          three_gallerysections_video_third_title
          three_gallerysections_video_third_caption
          three_gallerysections_video_second
          three_gallerysections_video_third
          three_gallerysections_image_thirdGatsby {
            childImageSharp {
              gatsbyImageData
            }
            url
            publicURL
          }
          three_gallerysections_image_third_alt
          three_gallerysections_video_first
          two_gallerysections_video_left
          two_gallerysections_vimeo_left
          two_gallerysections_video_right_title
          two_gallerysections_video_right_caption
          two_gallerysections_video_left_title
          two_gallerysections_video_left_caption
          two_gallerysections_image_left
          two_gallerysections_image_leftGatsby {
            childImageSharp {
              gatsbyImageData
            }
            url
            publicURL
          }
          two_gallerysections_image_left_alt
          two_gallerysections_video_right
          two_gallerysections_vimeo_right
          two_gallerysections_image_right
          two_gallerysections_image_rightGatsby {
            childImageSharp {
              gatsbyImageData
            }
            url
            publicURL
          }
          two_gallerysections_image_right_alt
          type
          video_embed_gallerysections_vimeo_video_id
          video_embed_gallerysections_vimeo_title
          video_embed_gallerysections_vimeo_caption
        }
      }
    }
  }
`;
