import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Media from '../Media';
import { fetchImage } from '../../utils/helper';
import '../../assets/styles/components/landscape-media.scss';

const LandscapeMedia = forwardRef((props, ref) => {
  const { data } = props;
  const {
    landscape_image_video_gallerysections_image,
    landscape_image_video_gallerysections_imageGatsby,
    landscape_image_video_gallerysections_image_alt,
    landscape_image_video_gallerysections_video,
    landscape_image_video_gallerysections_vimeo_video_id,
    landscape_image_video_gallerysections_title,
    landscape_image_video_gallerysections_caption,
    aspect_ratio,
  } = data;

  const gettingImage = useCallback((gatsbyImage, image) => fetchImage(gatsbyImage, image), []);

  return (
    <div className="gallery-section container" ref={ref}>
      <div className="landscape-media--wrapper">
        <div className={`landscape-media--image-wrapper ${aspect_ratio}`}>
          <Media
            img={gettingImage(landscape_image_video_gallerysections_imageGatsby, landscape_image_video_gallerysections_image)}
            alt={landscape_image_video_gallerysections_image_alt}
            htmlVideo={landscape_image_video_gallerysections_video}
            vimeoId={landscape_image_video_gallerysections_vimeo_video_id}
            aspectRatio={aspect_ratio}
          />
        </div>
        <div className="gallery-section-copy--wrapper">
          {landscape_image_video_gallerysections_title && (
            <h2>{landscape_image_video_gallerysections_title}</h2>
          )}
          {landscape_image_video_gallerysections_caption && (
            <p>{landscape_image_video_gallerysections_caption}</p>
          )}
        </div>
      </div>
    </div>
  );
});

LandscapeMedia.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default LandscapeMedia;
